import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import Success from "../../assets/glyphs/success.svg";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import LayoutSection from "../components/layout/LayoutSection";
import LayoutWrapper from "../components/layout/LayoutWrapper";
import SEO from "../components/seo";
import SiteHeader from "../components/SiteHeader";
import colors from "../components/style/colors";
import H3 from "../components/typography/H3";
import P2 from "../components/typography/P2";
import "../main.css";

const StyledSuccess = styled(Success)`
  width: 4.5em;
  margin-bottom: 2em;
  .a {
    stroke: ${colors.accent1};
    stroke-width: 0.1em;
  }
`;

const SuccessPage = props => {
  return (
    <>
      <CookieBanner />
      <LayoutWrapper>
        <SEO title="Authorization Completed" canonical={props.location.href} />
        <Helmet>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <SiteHeader dark />
        <LayoutSection small center>
          <StyledSuccess />
          <H3 center>Authorization Completed!</H3>
          <P2>Your account has been successfully added to Boxy Dashboard.</P2>
        </LayoutSection>
        <Footer legal />
      </LayoutWrapper>
    </>
  );
};

export default SuccessPage;
